// src/components/Index1.js

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import WOW from "wowjs";
import Header2 from "./../../Layout/Header2";
import Footer2 from "./../../Layout/Footer2";
import SideNav from "./../../Element/SideNav";
import VideoPopup from "./../../Element/VideoPopup";
import SubscriptionBox1 from "../SubscriptionBox/SubscriptionBox1";
import Slider1 from "./../../Element/Slider1";
import ScrollAnimation from "react-animate-on-scroll";
import axios from "axios";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

// Images
import modalimage from "./../../../images/banner/modalback.jpg";
import mainbanner from "./../../../images/main-slider/Gallery14.png";
import LogoWhite from "../../../images/logo1.png";
import BannerVideo from "../../../images/banner_video.mp4";

class Index1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userName: "",
      userNumber: "",
      userEmail: "",
      bhk2Selected: false,
      bhk3Selected: false,
      errorMessage: "",
    };
    this.localStorageSetup();
  }

  componentDidMount() {
    new WOW.WOW().init();

    if (localStorage.getItem("isNewUser") === "true") {
      this.setState({ showModal: true });
      localStorage.setItem("isNewUser", "false");
    }

    this.setupReopenTimer();
  }

  componentWillUnmount() {
    clearTimeout(this.reopenTimer);
  }

  localStorageSetup() {
    if (!localStorage.getItem("isNewUser")) {
      localStorage.setItem("isNewUser", "true");
    }
    if (!localStorage.getItem("hasVisited")) {
      localStorage.setItem("hasVisited", "false");
    }
    if (!localStorage.getItem("formCompleted")) {
      localStorage.setItem("formCompleted", "false");
    }
  }

  setupReopenTimer = () => {
    this.reopenTimer = setInterval(() => {
      if (localStorage.getItem("formCompleted") === "false") {
        this.setState({ showModal: true });
      }
    }, 60000); // Show popup every 30 seconds
  };

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === "checkbox") {
      this.setState({ [name]: checked });
    } else {
      this.setState({ [name]: value });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { userName, userNumber, userEmail, bhk2Selected, bhk3Selected } = this.state;
    if (!bhk2Selected && !bhk3Selected) {
      this.setState({ errorMessage: "Please select at least one option (2BHK or 3BHK)" });
      return; // Prevent form submission
    }


    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/email/popup`, {
        name: userName,
        number: userNumber,
        email: userEmail,
        bhk2: bhk2Selected,
        bhk3: bhk3Selected,
      });

      if (response.status === 200) {
        this.setState({ showModal: false, errorMessage: "" });
        localStorage.setItem("formCompleted", "true");
        clearInterval(this.reopenTimer);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      this.setState({ errorMessage: "Error submitting form. Please try again later." });
    }
  };

  handleClose = () => {
    this.setState({ showModal: false });
    localStorage.setItem("hasVisited", "true");
  };

  render() {
    const { showModal, userName, userNumber, userEmail, bhk2Selected, bhk3Selected, errorMessage } = this.state;

    return (
      <Fragment>
        <div className="sidenav-list">
          <SideNav />
        </div>

        <Header2 />
        {/* <div style={{ zIndex: "999999", position: "absolute" }}>
          <Modal size="lg" centered show={showModal} onHide={this.handleClose}>
            <div>
              <div className="row">
                <div className="col-md-6" style={{ padding: "0px" }}>
                  <div
                    style={{
                      backgroundImage: `url(${modalimage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div className="col-md-6" style={{ paddingLeft: "0px", backgroundColor: "#263d63" }}>
                  <Modal.Header closeButton>
                    <img src={LogoWhite} className="img-fluid mt-4" style={{ margin: "0 auto" }} width="200px" />
                  </Modal.Header>
                  <Modal.Body className="text-center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group className="mb-3 mt-4" controlId="formUserName">
                        <Form.Control
                          type="text"
                          placeholder="Your name*"
                          name="userName"
                          value={userName}
                          onChange={this.handleChange}
                          required
                          autoFocus
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserNumber">
                        <Form.Control
                          type="text"
                          placeholder="Your number*"
                          name="userNumber"
                          value={userNumber}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Control
                          type="email"
                          placeholder="Your Email*"
                          name="userEmail"
                          value={userEmail}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3 d-flex">
                        <Form.Check
                          type="checkbox"
                          name="bhk2Selected"
                          label="2BHK"
                          checked={bhk2Selected}
                          onChange={this.handleChange}
                        />
                        <Form.Check
                          type="checkbox"
                          name="bhk3Selected"
                          label="3BHK"
                          checked={bhk3Selected}
                          onChange={this.handleChange}
                          className="ml-2"
                        />
                      </Form.Group>

                      {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
                      <Button type="submit" variant="primary" style={{ width: "100%", backgroundColor: "#5d80b9" }}>
                        Submit
                      </Button>
                    </Form>
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
        </div> */}

        <div className="page-content bg-white">
          <video autoPlay loop muted id="video-background" className="w-100">
            <source src={BannerVideo} type="video/mp4" />
          </video>

          <section className="amenities-area">
            <ScrollAnimation animateIn="bounceInUp" duration="3" animateOnce={true} animatePreScroll={false}>
              <Slider1 />
            </ScrollAnimation>
          </section>
        </div>
        
        <Footer2 />
      </Fragment>
    );
  }
}

export default Index1;
