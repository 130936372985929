import React, { Fragment, Component } from "react";

import WOW from "wowjs";
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ScrollAnimation from "react-animate-on-scroll";
import Gallery8 from "./../../../images/main-slider/Gallery8.png";
import { SRLWrapper, useLightbox } from "simple-react-lightbox";
import Header from "./../../Layout/Header";
import Footer2 from "./../../Layout/Footer2";
import PageTitle from "./../../Layout/PageTitle";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import modalimage from "./../../../images/banner/modalback.jpg";
import LogoWhite from "../../../images/logo1.png";
import { Helmet } from 'react-helmet';
import axios from "axios";
import BannerVideo from "../../../images/banner_video.mp4";
//images
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import VideoPopup from "./../../Element/VideoPopup";

import mainbanner from "./../../../images/main-slider/Gallery14.png";

//Light Gallery on icon click
/* const Iconimage = props => {
  const { openLightbox } = useLightbox()
	
  return (
    
  )
} */

const FAQs = {
    "What sets Khetan Swiss Gardens apart in Mohali?": "Khetan Swiss Gardens stands out in Mohali with its meticulously crafted blend of Swiss architecture and essential modern amenities, offering a life of comfort and luxury.",
    "What is the location advantage of living here at 2 BHK Flats in Mohali?": "Located in the heart of Mohali, Khetan Swiss Gardens offers a prime location renowned for its serene landscapes and vibrant community, providing residents with easy access to both nature's tranquility and urban conveniences.",
    "How does Khetan Realty ensure security in the society?": "Khetan Realty ensures security at Khetan Swiss Gardens with Gated and Secure Community, 24*7 CCTV Surveillance, providing residents with peace of mind and safeguarding their well-being within the community.",
    "Is Khetan Swiss Gardens a RERA-approved project?": "Yes, Khetan Swiss Gardens is a RERA-approved project ensuring transparency and reliability for all homebuyers in Mohali."
};

const GalleryBox = ({ imageBlog }, props) => {
  const { openLightbox } = useLightbox();
  return (
    <div className="dlab-media dlab-img-overlay1 overlay-primary">
      <img src={imageBlog} alt="" />
      <div className="overlay-bx">
        <div className="overlay-icon">
          <a
            // href="https://www.youtube.com/watch?v=FALUYc5eX9s"
            href="https://www.youtube.com/watch?v=cxaaaLz6a7k"
            //
            className="popup-youtube"
          >
            <i className="fa fa-play icon-bx-xs"></i>
          </a>
          <a onClick={() => openLightbox(props.imageToOpen)} className=" ">
            <i className="fa fa-search icon-bx-xs"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

class SplitSection extends Component {

  
  render() {
    
    const items = [
      "Swimming Pool",
      "Gymnasium",
      "Luxury Clubhouse",
      "Indoor & Outdoor Games",
      "Open Air Theater",
      "Recreational Area",
      "Centre Plaza",
      "Outdoor Landscape",
      "Cafeteria",
    ];

    return (
    //   <Grid container spacing={2} style={{ padding: 20 }}>
    //     <Grid item xs={12} sm={6}>
          <ScrollAnimation
            animateIn="bounceInLeft"
            duration="2"
            animatePreScroll={false}
            animateOnce={true}
          >
            <h2
              className="title"
              style={{ textTransform: "uppercase", fontSize: "1.4em" }}
            >
              2 BHK Flats & Apartments with Luxurious Amenities in Mohali{" "}
            </h2>
            <p style={{ fontSize: "1em", fontWeight: "300" }}>
            Built with meticulous attention to detail, Khetan Swiss Gardens presents 2 BHK Apartments on Airport Road, Mohali, blending Swiss Architectural Elegance with essential modern amenities for a life of unparalleled comfort
            </p>
            <List>
              {items.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <i
                      className="fa fa-check-circle"
                      style={{ color: "#263d63", fontSize: "1.5em" }}
                    ></i>
                    {/* <CheckCircleIcon /> */}
                  </ListItemIcon>
                  <ListItemText primary={item} />
                </ListItem>
              ))}
            </List>
          </ScrollAnimation>
        // </Grid>

        // {/* <Grid item xs={12} sm={6}>
        //   <ScrollAnimation animateIn="bounceInRight" duration="2" animateOnce={true}  animatePreScroll={false}>
        //     <img src={SwimmingImg} alt="Placeholder" style={{ width: '100%', height: 'auto' }} />
        //     </ScrollAnimation>
        // </Grid> */}
    //   </Grid>
    );
  }
}

class FlatstwoBHk extends Component {
  componentDidMount() {
    new WOW.WOW().init();
  }
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      userName: "",
      userNumber: "",
      userEmail: "",
      errorMessage: "",
    };
  }
  handleClose = () => {
    this.setState({ showModal: false });
  };
  handleOpen = () =>{
    this.setState({ showModal: true });
  }
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const { userName, userNumber, userEmail,  } = this.state;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/email/popup`, {
        name: userName,
        number: userNumber,
        email: userEmail,
        bhk2: true,
      });

      if (response.status === 200) {
        this.setState({ showModal: false, errorMessage: "" });
        localStorage.setItem("formCompleted", "true");
        clearInterval(this.reopenTimer);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      this.setState({ errorMessage: "Error submitting form. Please try again later." });
    }
  };


  render() {
    const { showModal, userName, userNumber, userEmail, errorMessage } = this.state;
    return (
      <Fragment>
        <Helmet>
        <title>Khetan realty - 2BHK Flats and Apartments in Mohali</title>
        <meta name="description" content="Khetan Swiss Gardens offers Luxurious 2BHK Flats & Apartments in Mohali, Airport Road with ultra-modern amenities" />
        <meta name="keywords" content="2BHK, Flats, Apartment, Khetan Swiss Gardens" />
      </Helmet>

        <Header />
        <div className="page-content bg-white">
          {/*  banner  */}
          <PageTitle
            motherMenu="Khetan Swiss Gardens"
            activeMenu="2 BHK Flats in Mohali"
          />
          <Modal size="lg" centered show={showModal} onHide={this.handleClose}>
            <div>
              <div className="row">
                <div className="col-md-6" style={{ padding: "0px" }}>
                  <div
                    style={{
                      backgroundImage: `url(${modalimage})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  ></div>
                </div>
                <div className="col-md-6" style={{ paddingLeft: "0px", backgroundColor: "#263d63" }}>
                  <Modal.Header closeButton>
                    <img src={LogoWhite} className="img-fluid mt-4" style={{ margin: "0 auto" }} width="200px" />
                  </Modal.Header>
                  <Modal.Body className="text-center">
                    <Form onSubmit={this.handleSubmit}>
                      <Form.Group className="mb-3 mt-4" controlId="formUserName">
                        <Form.Control
                          type="text"
                          className="modal-input"
                          placeholder="Your name*"
                          name="userName"
                          value={userName}
                          onChange={this.handleChange}
                          required
                          autoFocus
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserNumber">
                        <Form.Control
                          type="text"
                          className="modal-input"
                          placeholder="Your number*"
                          name="userNumber"
                          value={userNumber}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formUserEmail">
                        <Form.Control
                          type="email"
                          className="modal-input"
                          placeholder="Your Email*"
                          name="userEmail"
                          value={userEmail}
                          onChange={this.handleChange}
                          required
                        />
                      </Form.Group>
                      {errorMessage && <div className="text-danger mb-3">{errorMessage}</div>}
                      <Button type="submit" variant="primary" style={{ width: "100%", backgroundColor: "#5d80b9", zIndex: "9999" }}>
                        Submit
                      </Button>
                    </Form>
                  </Modal.Body>
                </div>
              </div>
            </div>
          </Modal>
          {/*  Section-1 Start  */}
          <section
            className="content-inner about-box"
            // data-content="ON GOING PROJECT"
            id="sidenav_aboutUs"
          >
            {/* <div className="about-bg"></div> */}

            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6">
                <ScrollAnimation animateIn="bounceInLeft" duration="3" animateOnce={true} animatePreScroll={false}>
                  <img src={Gallery8} className="img-fluid" />
                  </ScrollAnimation>
                </div>

                <div className="col-md-6 col-lg-6">
                  <ScrollAnimation
                    animateIn="bounceInRight"
                    duration="3"
                    animateOnce={true}
                    animatePreScroll={false}
                  >
                    <div className="section-head">
                      <h2 className="title" style={{ fontSize: "1.7em" }}>
                      2 BHK Flats in Mohali 
                      </h2>
                      <p> Mohali Punjab </p>
                      <div className="dlab-separator bg-primary "></div>
                      <p style={{ fontSize: "1em", fontWeight: "300" }}>
                      Live your best life at KHETAN SWISS GARDENS where every moment is imbued with the essence of family and modernity amidst lush greenery. Our premium 2 BHK Flats in Mohali epitomise a blend of convenience and natural beauty. Located on Airport Road, Mohali and renowned for its serene landscapes and vibrant community spirit, here you'll find more than just a home.

Embrace peace of mind with our 2 BHK Apartments Gated Community Living in Mohali and revel in seamless connectivity to all that matters. Stay fit and rejuvenated with exclusive amenities including a state-of-the-art gym, yoga room and invigorating jogging tracks. For those seeking solace, our spiritual spaces offer sanctuary amidst the hustle and bustle.

                      </p>
                      
                    </div>
                   

                    <div  target="_blank" rel="noopener noreferrer" onClick={()=>this.handleOpen()} className="btn btn-primary m-responsive">
                      Contact us
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
            {/* <SplitSection /> */}
          </section>
          <div className="container">
            <SplitSection />
          </div>
          <div className="container">
          <h2 className="title" style={{ fontSize: "1.7em" }}>
                        FAQ's{" "}
                      </h2>
            {Object.entries(FAQs).map(([key, value]) => {
              return (
                <div style={{
                    marginBottom:"20px"
                }}>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ArrowDownwardIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>
                    {key}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                    {value}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                </div>
              );
            })}
          </div>
          {/* <div
            className="banner-three"
            style={{
              backgroundImage: "url(" + mainbanner + ")",
              backgroundSize: "cover",
              // marginBottom: "5.2rem",
            }}
            data-content="HOME"
            id="sidenav_home"
          >
            <div
              className="container"
              style={{
                // position: "absolute",
                top: "10rem",
                left: "10rem",
                zIndex: "15",
              }}
            >
              <div className="row align-items-center banner-inner text-center">
                <div className="col-md-12">
                  <ScrollAnimation
                    animateIn="bounceInUp"
                    duration="3"
                    animateOnce={true}
                  >
                    <div className="content-blog" style={{ marginLeft: "0px" }}>
                      <div
                        className="banner-content"
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          width: "100%",
                          height: "100%",
                          padding: "20px",
                        }}
                      >
                    
                      </div>
                      <VideoPopup />
                    </div>
                  </ScrollAnimation>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <Footer2 />
      </Fragment>
    );
  }
}
export { GalleryBox };
export default FlatstwoBHk;
